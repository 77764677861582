import service from '@/utils/request'; // @Tags api
// @Summary 分页获取角色列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body modelInterface.PageInfo true "分页获取用户列表"
// @Success 200 {string} json "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /api/getApiList [post]
// {
//  page     int
//	pageSize int
// }

export var createTemp = function createTemp(data) {
  return service({
    url: "/autoCode/createTemp",
    method: 'post',
    data: data,
    responseType: 'blob'
  });
}; // @Tags SysApi
// @Summary 获取当前所有数据库
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Success 200 {string} string "{"success":true,"data":{},"msg":"创建成功"}"
// @Router /autoCode/getDatabase [get]

export var getDB = function getDB() {
  return service({
    url: "/autoCode/getDB",
    method: 'get'
  });
}; // @Tags SysApi
// @Summary 获取当前数据库所有表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Success 200 {string} string "{"success":true,"data":{},"msg":"创建成功"}"
// @Router /autoCode/getTables [get]

export var getTable = function getTable(params) {
  return service({
    url: "/autoCode/getTables",
    method: 'get',
    params: params
  });
}; // @Tags SysApi
// @Summary 获取当前数据库所有表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Success 200 {string} string "{"success":true,"data":{},"msg":"创建成功"}"
// @Router /autoCode/getColumn [get]

export var getColumn = function getColumn(params) {
  return service({
    url: "/autoCode/getColumn",
    method: 'get',
    params: params
  });
};